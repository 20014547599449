import { ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import type { TEventCardFocusContext } from 'contexts/eventCardFocus';
import { EventCardFocusProvider } from 'contexts/eventCardFocus';
import useFocusWithin from 'hooks/useFocusWithin';
import React, { useRef } from 'react';
import { EVENT_COLOR_MAP } from 'utils/eventColors';

interface Props {
  className?: string;
  colorFamily?: ColorFamily;
  title: string;
  readOnly: boolean;
  children:
    | ((props: TEventCardFocusContext) => React.ReactNode)
    | React.ReactNode;
}

export default React.memo(function EventCard({
  className,
  title,
  children,
  colorFamily = ColorFamily.Gray,
  readOnly,
}: Props) {
  const ref = useRef<HTMLLabelElement | null>(null);
  const { hasFocus } = useFocusWithin(ref);
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  const hasFocusAndIsEditable = hasFocus && !readOnly;

  return (
    <EventCardFocusProvider hasFocus={hasFocusAndIsEditable}>
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
      <label
        ref={ref}
        className={classNames(
          'eventcard relative flex flex-1 flex-col rounded-lg p-2 ring-inset transition',
          className,
          colorMap.ring,
          {
            'bg-gray-100 ring-0 ring-opacity-0 dark:bg-gray-600':
              !hasFocusAndIsEditable,
            'ring-2 ring-opacity-30': hasFocusAndIsEditable,
            [`${colorMap.ring} ${colorMap.focusBackground} ${colorMap.text} ${colorMap.selection}`]:
              hasFocusAndIsEditable,
          }
        )}
        onClick={(e) => {
          // don't prevent links from being opened
          if ((e.nativeEvent.target as HTMLElement).tagName !== 'A') {
            e.preventDefault();
          }
          // prevent clicks but keep focus
          ref.current?.focus();
        }}
      >
        {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
        <small
          className={classNames(
            'mb-0.5 text-[11px] font-black uppercase leading-snug tracking-wider transition-colors',
            {
              [colorMap.text]: hasFocusAndIsEditable,
              'text-gray-400': !hasFocusAndIsEditable,
            }
          )}
        >
          {title}
        </small>
        {typeof children === 'function'
          ? children({ hasFocus: hasFocusAndIsEditable })
          : children}
      </label>
    </EventCardFocusProvider>
  );
});
