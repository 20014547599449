import { CalendarStartsOn_Enum, ColorFamily } from '@graphql-types@';
import classNames from 'classnames';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import { usePreferences } from 'hooks/usePreferences';
import { DateTime } from 'luxon';
import Button from 'joy/Button';
import dynamic from 'next/dynamic';
import React, {
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import IconChevronLeft from '../../Icons/IconChevronLeft';
import IconChevronRight from '../../Icons/IconChevronRight';
import EventCard from '../EventCard';
import { EventTimePicker } from '../EventTimePicker';
import dateStyles from './EventDate.module.css';
import { enUS } from 'date-fns/locale';

const DATE_BEGINNING_FORMAT = 'dd MMM yyyy'; // ex: Thursday 11 Feb
const DatePicker = dynamic(() => import('react-datepicker'));

interface EventDateTimePickerProps {
  startAt: DateTime;
  endAt: DateTime;
  isAllDay: boolean;
  onChangeStart: (value: DateTime) => void;
  onChangeEnd: (value: DateTime) => void;
  colorFamily: ColorFamily;
  readOnly: boolean;
}

export default function EventDateTimePicker({
  startAt,
  endAt,
  onChangeStart,
  onChangeEnd,
  colorFamily,
  isAllDay,
  readOnly,
}: EventDateTimePickerProps) {
  const preferences = usePreferences();
  const [isDatePickerStartAtOpen, setIsDatePickerStartAtOpen] = useState(false);
  const [isDatePickerEndAtOpen, setIsDatePickerEndAtOpen] = useState(false);

  const dateControlStartAtRef = useRef<HTMLDivElement>(null);
  const dateControlEndAtRef = useRef<HTMLDivElement>(null);

  const weekStartsOn = useMemo(() => {
    if (!preferences) return 0;

    const preferredStartDay = preferences.calendarStartsOn;
    switch (preferredStartDay) {
      case CalendarStartsOn_Enum.Monday:
        return 1;
      case CalendarStartsOn_Enum.Sunday:
        return 0;

      default:
        return 0;
    }
  }, [preferences]);

  const handleSelectStartAt = useCallback(
    (newStartDate: DateTime, timeOnly = true) => {
      if (timeOnly) {
        newStartDate = startAt.set({
          hour: newStartDate.hour,
          minute: newStartDate.minute,
          second: newStartDate.second,
        });
      }

      const prevEventDuration = endAt.diff(startAt, 'minutes').minutes;
      const newEndDate = newStartDate.plus({ minutes: prevEventDuration });

      if (newStartDate <= endAt) {
        onChangeStart(newStartDate);
        onChangeEnd(newEndDate);
      } else {
        onChangeEnd(newEndDate);
        onChangeStart(newStartDate);
      }
    },
    [endAt, onChangeEnd, onChangeStart, startAt]
  );

  const handleSelectEndTime = useCallback(
    (newEndDate: DateTime, timeOnly = true) => {
      if (timeOnly) {
        newEndDate = startAt.set({
          hour: newEndDate.hour,
          minute: newEndDate.minute,
          second: newEndDate.second,
        });
      }

      // Prevent more than 1 day events for non allDay events
      const prevEventDuration = endAt.diff(startAt, 'minutes').minutes;

      const isBeforeStart = isAllDay
        ? newEndDate.diff(startAt, 'days').days < 0
        : newEndDate.diff(startAt, 'minutes').minutes < 15;

      if (isBeforeStart) {
        const newStart = newEndDate.minus({ minutes: prevEventDuration });
        onChangeStart(newStart);
      } else if (newEndDate.diff(startAt, 'days').days > 0 && !isAllDay) {
        const newStart = newEndDate.set({
          hour: startAt.get('hour'),
          minute: startAt.minute,
        });
        onChangeStart(newStart);
      }
      onChangeEnd(newEndDate);
    },
    [endAt, isAllDay, onChangeEnd, onChangeStart, startAt]
  );

  const onDatePickerClickOutside = useCallback((e, trigger) => {
    let setDatepickerOpen;
    let datePickerRef;

    switch (trigger) {
      case 'startAt':
        setDatepickerOpen = setIsDatePickerStartAtOpen;
        datePickerRef = dateControlStartAtRef;
        break;
      case 'endAt':
        setDatepickerOpen = setIsDatePickerEndAtOpen;
        datePickerRef = dateControlEndAtRef;
        break;

      default:
        return;
    }

    if (
      e.target instanceof HTMLElement &&
      !datePickerRef?.current?.contains(e.target?.offsetParent)
    ) {
      setDatepickerOpen(false);
    }
  }, []);

  const onDatePickerStartAtClickOutside = useCallback(
    (e) => onDatePickerClickOutside(e, 'startAt'),
    [onDatePickerClickOutside]
  );
  const onDatePickerEndAtClickOutside = useCallback(
    (e) => onDatePickerClickOutside(e, 'endAt'),
    [onDatePickerClickOutside]
  );

  return (
    <div className="flex w-full items-center space-x-1">
      <EventCard title="From" colorFamily={colorFamily} readOnly={readOnly}>
        {!isAllDay && (
          <EventTimePicker
            time={startAt}
            onChange={handleSelectStartAt}
            colorFamily={colorFamily}
            readOnly={readOnly}
            className={'max-w-[90px]'}
            menuWidth={-1}
          />
        )}
        <div
          className={classNames(dateStyles.datepicker)}
          role="presentation"
          // Prevent clicking on DatePicker from bubbling up.
          onClick={(e) => e.stopPropagation()}
          ref={dateControlStartAtRef}
        >
          <DatePicker
            portalId="event-datepicker-start"
            disabled={readOnly}
            popperContainer={PopperContainer}
            dateFormat={DATE_BEGINNING_FORMAT}
            popperClassName={dateStyles[`event-color--${colorFamily}`]}
            popperPlacement="bottom-start"
            selected={startAt.toJSDate()}
            open={isDatePickerStartAtOpen}
            locale={{
              ...enUS,
              options: {
                ...enUS.options,
                weekStartsOn,
              },
            }}
            onChange={(date: Date) => {
              handleSelectStartAt(DateTime.fromJSDate(date), false);
              setIsDatePickerStartAtOpen(false);
            }}
            onClickOutside={onDatePickerStartAtClickOutside}
            maxDate={isAllDay ? endAt.toJSDate() : undefined}
            renderCustomHeader={CustomHeader}
            dayClassName={() => 'text-secondary text-sm font-medium'}
            customInput={
              <DatePickerCustomInput
                colorFamily={colorFamily}
                isOpen={isDatePickerStartAtOpen}
                isReadOnly={readOnly}
                onDatepickerHeaderClick={() =>
                  setIsDatePickerStartAtOpen(!isDatePickerStartAtOpen)
                }
              />
            }
          />
        </div>
      </EventCard>

      <EventCard title="To" colorFamily={colorFamily} readOnly={readOnly}>
        {!isAllDay && (
          <EventTimePicker
            time={endAt}
            minTime={startAt}
            onChange={handleSelectEndTime}
            colorFamily={colorFamily}
            readOnly={readOnly}
            className={'max-w-[90px]'}
            menuWidth={-1}
          />
        )}
        <div
          className={classNames(dateStyles.datepicker)}
          role="presentation"
          // Prevent clicking on DatePicker from bubbling up.
          onClick={(e) => e.stopPropagation()}
          ref={dateControlEndAtRef}
        >
          <DatePicker
            portalId="event-datepicker-end"
            disabled={readOnly}
            popperContainer={PopperContainer}
            dateFormat={DATE_BEGINNING_FORMAT}
            popperClassName={dateStyles[`event-color--${colorFamily}`]}
            popperPlacement="bottom-start"
            open={isDatePickerEndAtOpen}
            selected={endAt.toJSDate()}
            locale={{
              ...enUS,
              options: {
                ...enUS.options,
                weekStartsOn,
              },
            }}
            minDate={isAllDay ? startAt.toJSDate() : undefined}
            onChange={(date: Date) => {
              handleSelectEndTime(DateTime.fromJSDate(date), false);
              setIsDatePickerEndAtOpen(false);
            }}
            onClickOutside={onDatePickerEndAtClickOutside}
            renderCustomHeader={CustomHeader}
            dayClassName={() => 'text-secondary text-sm font-medium'}
            customInput={
              <DatePickerCustomInput
                colorFamily={colorFamily}
                isOpen={isDatePickerEndAtOpen}
                isReadOnly={readOnly}
                onDatepickerHeaderClick={() =>
                  setIsDatePickerEndAtOpen(!isDatePickerEndAtOpen)
                }
              />
            }
          />
        </div>
      </EventCard>
    </div>
  );
}

export const CustomHeader: ReactDatePickerProps['renderCustomHeader'] = ({
  date,
  decreaseMonth,
  increaseMonth,
}) => {
  return (
    <div className="text-primary flex items-center justify-between px-3.5 py-1 text-base">
      <div className="flex items-center space-x-1">
        <span className="font-semibold">
          {DateTime.fromJSDate(date).toFormat('MMMM ')}
          {DateTime.fromJSDate(date).toFormat('yyyy')}
        </span>
      </div>
      <div className="flex items-center space-x-4">
        <Button
          className="text-icons hover:text-gray-600 dark:hover:text-gray-200"
          onClick={decreaseMonth}
        >
          <IconChevronLeft />
        </Button>
        <Button
          className="text-icons hover:text-gray-600 dark:hover:text-gray-200"
          onClick={increaseMonth}
        >
          <IconChevronRight />
        </Button>
      </div>
    </div>
  );
};

type DatePickerCustomInputProps = {
  onDatepickerHeaderClick: () => void;
  value?: string;
  isOpen: boolean;
  colorFamily: ColorFamily;
  isReadOnly: boolean;
};

const DatePickerCustomInput = forwardRef<
  HTMLButtonElement,
  DatePickerCustomInputProps
>(({ value, onDatepickerHeaderClick, colorFamily, isReadOnly }, ref) => {
  const { hasFocus } = useEventCardFocusContext();
  const colorMap = EVENT_COLOR_MAP[colorFamily];

  return (
    <Button
      variant="popoverIcon"
      className={classNames(
        '!active:scale-98 -ml-1 -mb-1 flex !h-auto !w-auto px-1 py-1 text-xs leading-none',
        {
          [`${colorMap.text} ${colorMap.button} hover:!bg-opacity-100 focus:!bg-opacity-100`]:
            hasFocus,
          'cursor-default': isReadOnly,
        }
      )}
      onClick={onDatepickerHeaderClick}
      ref={ref}
      disabled={isReadOnly}
    >
      {value}
    </Button>
  );
});
DatePickerCustomInput.displayName = 'DatePickerCustomInput';

const PopperContainer = ({ children }: { children: React.ReactNode }) =>
  ReactDOM.createPortal(
    <div className={dateStyles.datepicker}>{children}</div>,
    document.body
  );
