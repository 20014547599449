import React from 'react';
import cn from 'classnames';
import { IconSVGProps } from 'types/icon';
import IconChevronRight from './IconChevronRight';

const IconChevronLeft: React.FC<IconSVGProps> = ({ className = 'h-5 w-5' }) => {
  return <IconChevronRight className={cn('rotate-180 transform', className)} />;
};

export default IconChevronLeft;
