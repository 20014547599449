import { ColorFamily } from '@graphql-types@';
import { noop } from '@remirror/core-helpers';
import classNames from 'classnames';
import { useEventCardFocusContext } from 'contexts/eventCardFocus';
import { useUi24HourClock } from 'hooks/usePreferences';
import useTimezone from 'hooks/useTimeZone';
import hotkeys from 'hotkeys-js';
import ComboBox, {
  ComboBoxActions,
  ComboBoxItem,
  ComboBoxItemOption,
} from 'joy/ComboBox';
import Input from 'joy/Input';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TEST_ID_EVENT_TIME_PICKER_INPUT } from 'utils/constants';
import { EVENT_COLOR_MAP } from 'utils/eventColors';
import { getTimeFormat } from 'utils/format';
import {
  formatDurationMinutes,
  MINUTES_IN_A_DAY,
  parseTimeInput,
  roundToNearestQuarterPoint,
} from 'utils/time';

const MINUTES_PER_SLOT = 15;

interface EventTimePickerProps {
  time: DateTime;
  minTime?: DateTime | undefined;
  onChange: (dateTime: DateTime) => void;
  colorFamily: ColorFamily;
  readOnly: boolean;
  className?: string;
  menuWidth?: number;
}

export function EventTimePicker({
  time,
  onChange,
  colorFamily,
  minTime,
  readOnly,
  menuWidth,
  ...props
}: EventTimePickerProps) {
  const ui24HourClock = useUi24HourClock();
  const type = minTime ? ('end' as const) : ('start' as const);
  const [inputFocused, setInputFocused] = useState(false);
  const timezone = useTimezone();
  const localStringTime = useMemo(() => {
    return time.toFormat(getTimeFormat(ui24HourClock).long);
  }, [time, ui24HourClock]);

  const activeOptionRef = useRef<HTMLLIElement | null>(null);
  const [input, setInput] = useState(localStringTime);
  const ignoreBlur = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const colorMap = EVENT_COLOR_MAP[colorFamily];
  const { hasFocus } = useEventCardFocusContext();

  useEffect(() => {
    if (!inputFocused) return noop();

    setTimeout(() => {
      if (!activeOptionRef.current) return noop();

      // For Jest
      if (typeof activeOptionRef.current.scrollIntoView !== 'function')
        return noop();
      activeOptionRef.current.scrollIntoView({
        block: 'center',
      });
    }, 0);
  }, [inputFocused]);

  const dropdownTimes = useMemo(
    () =>
      inputFocused
        ? getTimeOptions(
            type,
            minTime || time,
            getTimeFormat(ui24HourClock).long
          )
        : [],
    [type, minTime, time, ui24HourClock, inputFocused]
  );

  useEffect(() => {
    setInput(localStringTime);
  }, [localStringTime]);

  const validTimeAsText = useMemo(() => {
    const parsed = parseTimeInput(input, timezone);
    return parsed.isValid ? parsed : undefined;
  }, [input, timezone]);

  const onFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
    setInputFocused(true);
    event.currentTarget.select();
  }, []);

  const onBlur = useCallback(() => {
    // Workaround for a race condition when:
    // setInput and onChange is called, blur is called
    // and then onBlur is triggered before the component can re-render with the new time.
    if (ignoreBlur.current) {
      ignoreBlur.current = false;
      return;
    }

    if (!validTimeAsText) {
      setInput(localStringTime);
    } else {
      onChange(validTimeAsText);
    }
    setInputFocused(false);
  }, [localStringTime, onChange, validTimeAsText]);

  const onSelect = useCallback(
    (
      value: string,
      item: ComboBoxItem | undefined,
      actions: ComboBoxActions
    ) => {
      // Submit the current user input
      if (!item || item.type !== 'option') {
        actions.setInputFocused(false);
        return;
      }

      setInput(value);
      const dropdownTime = dropdownTimes.find(
        (time) => time.readable.toLowerCase() === value.toLowerCase()
      );

      if (!dropdownTime) return;

      onChange(dropdownTime.raw);
      ignoreBlur.current = true;
      inputRef.current?.blur();
    },
    [dropdownTimes, onChange]
  );

  const blurInput = useCallback(
    () => inputRef?.current && inputRef.current.focus(),
    [inputRef]
  );

  return (
    <div
      {...props}
      onKeyDown={() => hotkeys.isPressed('esc') && blurInput()}
      onClick={() => inputRef?.current?.focus()}
      role="presentation"
    >
      <ComboBox
        aria-label="Date"
        onSubmit={onSelect}
        onInputChange={setInput}
        items={dropdownTimes
          .map<ComboBoxItem>((x) => {
            return {
              type: 'option',
              value: x.readable,
              text: x.readable,
              subtext: x.duration
                ? ` (${formatDurationMinutes(x.duration * 60)})`
                : undefined,
            };
          })
          .filter(
            (x) =>
              (x as ComboBoxItemOption).value.includes(input) ||
              input == '' ||
              input === localStringTime
          )}
        inputRef={inputRef}
        className="w-full items-center"
        menuWidth={menuWidth}
      >
        <Input
          disabled={readOnly}
          ref={inputRef}
          className={classNames(
            'flex w-full self-center bg-transparent text-sm font-medium outline-none',
            { [colorMap.placeholder]: hasFocus }
          )}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          aria-invalid={validTimeAsText == null}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onBlur();
            }
          }}
          name={`time-${type}`}
          data-testid={TEST_ID_EVENT_TIME_PICKER_INPUT}
        />
      </ComboBox>
    </div>
  );
}

const allTimesForDay = (
  formatting: string,
  date: DateTime,
  minutesPerSlot = MINUTES_PER_SLOT
) => {
  return new Array(MINUTES_IN_A_DAY / minutesPerSlot)
    .fill(0)
    .map((_, index) => {
      const totalMins = index * minutesPerSlot;
      const minutes = totalMins % 60;
      const hours = totalMins / 60;

      const raw = date.set({ hour: hours, minute: minutes });
      const readable = raw.toFormat(formatting);

      return {
        readable,
        raw,
      };
    });
};

export type TimeOption = {
  raw: DateTime;
  readable: string;
  duration?: number;
};

export const getTimeOptions = (
  dropdownType: 'start' | 'end',
  startAt: DateTime,
  formatting: string
): TimeOption[] => {
  if (dropdownType === 'start') {
    return allTimesForDay(formatting, startAt, MINUTES_PER_SLOT);
  }

  // dropdownType === 'end' 👇🏻
  const EOD = startAt.endOf('day');
  const numberOfTimeSlots = Math.round(
    EOD.diff(startAt, 'minutes').as('minutes') / MINUTES_PER_SLOT
  );

  const endAtTimes = new Array(numberOfTimeSlots);

  return endAtTimes.fill(0).map((_, index) => {
    const durationInMinutes = MINUTES_PER_SLOT * (index + 1);
    const slotDate = startAt.plus({ minutes: durationInMinutes });

    return {
      raw: slotDate,
      readable: slotDate.toFormat(formatting),
      duration: roundToNearestQuarterPoint(durationInMinutes / 60),
    };
  });
};
