import React from 'react';
import createCtx from './createCtx';

export type TEventCardFocusContext = {
  hasFocus: boolean;
};

export const [useEventCardFocusContext, EventCardFocusContext] =
  createCtx<TEventCardFocusContext>();

export const EventCardFocusProvider: React.FC<TEventCardFocusContext> = ({
  children,
  ...props
}) => {
  return (
    <EventCardFocusContext value={props}>{children}</EventCardFocusContext>
  );
};
