import React from 'react';

export default function IconCaretRight({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6"
      height="10"
      fill="none"
      viewBox="0 0 6 10"
      className={className}
    >
      <path
        fill="currentColor"
        d="M0 1.414v7.172c0 .89 1.077 1.337 1.707.707l3.586-3.586a1 1 0 000-1.414L1.707.707C1.077.077 0 .523 0 1.414z"
      ></path>
    </svg>
  );
}
