import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export default function useFocusWithin(
  ref: MutableRefObject<HTMLElement | null>
) {
  const [isFocusWithin, setIsFocusWithin] = useState(false);

  const checkFocus = useCallback(() => {
    if (!ref.current) return;

    setIsFocusWithin(ref.current.contains(document.activeElement));
  }, [ref]);

  useEffect(() => {
    if (ref.current) {
      document.addEventListener('focusin', checkFocus, true);
      document.addEventListener('focusout', checkFocus, true);
    }

    return () => {
      document.removeEventListener('focusin', checkFocus);
      document.removeEventListener('focusout', checkFocus);
    };
  }, [checkFocus, ref]);

  return { hasFocus: isFocusWithin, checkFocus };
}
